import { checkIsMobile } from "@centered/helpers"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"
import { useEffect } from "react"

const App = dynamic(() => import("../../components/app/App"), {
  ssr: false,
})

export default function Index(): JSX.Element {
  const router = useRouter()

  useEffect(() => {
    document.documentElement.classList.add("app")
    const isMobile = checkIsMobile()

    if (isMobile) {
       router.replace('/download')
    }

    return () => {
      document.documentElement.classList.remove("app")
    }
  }, [router])

  return (
    <>
      <Head>
        <title>Sukha</title>
      </Head>

      <App />
    </>
  )
}
